import {
  clone,
  timeout,
} from './common-helpers'

import {
  getKeyValueList,
} from './object-helpers'

import {
  localeIncludes,
} from './text-helpers'

import {
  isBetween,
} from './number-helpers'

import {
  patchItem,
} from './collection-helpers'

import {
  createArray,
  shuffleArray,
} from './array-helpers'

import Timer from './time/Timer'

export default {
  clone,
  timeout,
  getKeyValueList,
  localeIncludes,
  isBetween,
  patchItem,
  createArray,
  shuffleArray,
  Timer,
}
