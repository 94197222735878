export default class Timer {
  constructor () {
    this.startTime = null
    this.stopTime = null
  }

  get duration () {
    return this.startTime && this.stopTime
      ? this.stopTime - this.startTime
      : false
  }

  start () {
    this.startTime = Date.now()
    this.stopTime = null
  }

  stop () {
    if (this.startTime) {
      this.stopTime = Date.now()
    }
    return this.duration
  }
}
