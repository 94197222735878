<template>
  <router-view
    v-if="game"
    :game="game"
    :player-id="currentPlayerId"
    :ready-to-render="isExpectedRouteName"
    @change-week-end-planned="updateWeekEndPlanned"
  />
</template>

<script>
import _throttle from 'lodash/throttle'
import Helpers from 'src/utils/helpers'
import Repositories from 'src/repositories'
import { mapActions, mapGetters } from 'vuex'

import Repository from 'src/services/repository'
import AVAILABLE_STATUS from '../availableStatus'

export default {
  name: 'MultiProjectStandardContainer',
  props: {
    gameId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    game: null,
  }),
  computed: {
    ...mapGetters('me', {
      trainingSessionPlayerId: 'trainingSessionPlayerId',
    }),
    ...mapGetters('session', {
      gameStatusListByResourceId: 'gameStatusListByResourceId',
    }),
    gameStatus () {
      return this.gameStatusListByResourceId[this.gameId]
    },
    currentPlayer () {
      return this.game?.playerList
        ?.find(player => player.trainingSessionPlayerId === this.trainingSessionPlayerId)
    },
    currentPlayerId () {
      return this.currentPlayer?.id
    },
    currentPlayerStatus () {
      return this.currentPlayer?.status
    },
    expectedRouteName () {
      if (this.currentPlayer?.auto) {
        return 'multiProjectStandardAutomatic'
      }
      return this.$options.expectedRoutesForStatus[this.currentPlayerStatus]
    },
    isExpectedRouteName () {
      return this.$route.name === this.expectedRouteName
    },
  },
  sockets: {
    multiProjectSessionBulkChanges () {
      this.fetchData()
    },
    multiProjectSessionPlayerWaitForStart ({ multiProjectSessionPlayerId }) {
      if (multiProjectSessionPlayerId !== this.currentPlayerId) {
        this.updatePlayerStatus({
          id: multiProjectSessionPlayerId,
          status: AVAILABLE_STATUS.waitForStart,
        })
      }
    },
    multiProjectSessionPlayerReadyToStartGame ({ multiProjectSessionPlayerId }) {
      this.updatePlayerStatus({
        id: multiProjectSessionPlayerId,
        status: AVAILABLE_STATUS.readyToStartGame,
      })
    },
    multiProjectSessionPlayerCanStartProjectBlueOverCapacity ({ multiProjectSessionPlayerId }) {
      if (multiProjectSessionPlayerId === this.currentPlayerId) {
        this.fetchData()
      }
    },
    multiProjectSessionPlayerAutoModeChanged ({ multiProjectSessionPlayerId }) {
      if (multiProjectSessionPlayerId === this.currentPlayerId) {
        this.fetchData()
      }
    },
  },
  watch: {
    expectedRouteName () {
      this.ensureRouteIsValid()
    },
    gameStatus () {
      if (this.gameStatus !== 'opened') {
        this.$router.go(-1)
      }
    },
  },
  mounted () {
    this.fetchData()
    this.setTitle()
  },
  methods: {
    ...mapActions('ui', {
      setTitle: 'setTitle',
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    fetchData: _throttle(async function fetchData () {
      this.game = await Repositories.multiproject.get(this.gameId)
    }, 200, { leading: true, trailing: true }),
    ensureRouteIsValid () {
      if (this.expectedRouteName && !this.isExpectedRouteName) {
        this.$router.replace({ name: this.expectedRouteName })
      }
    },
    updatePlayerStatus ({ id, ...updatedFields }) {
      this.game.playerList = Helpers.patchItem({
        collection: this.game.playerList,
        identifier: 'id',
        patch: { id, ...updatedFields },
      })
    },
    async sendPlayerAction (actionName) {
      try {
        await Repositories.multiprojectPlayer.sendAction({
          gameId: this.game.id,
          playerId: this.currentPlayerId,
          actionName,
        })
        await this.fetchData()
      }
      catch (error) {
        this.showErrorNotification(Repository.utils.parseErrorMessage(error))
        throw error
      }
    },
    async updateWeekEndPlanned (newWeekEndPlanned) {
      try {
        await Repositories.multiprojectPlayer.patch({
          gameId: this.game.id,
          playerId: this.currentPlayerId,
          weekEndPlanned: newWeekEndPlanned,
        })
        await this.fetchData()
        this.showSuccessNotification(this.$t('multiproject.common.weekEndPlannedChanged'))
      }
      catch (error) {
        this.showErrorNotification(Repository.utils.parseErrorMessage(error))
        throw error
      }
    },
  },
  provide () {
    return {
      sendPlayerAction: this.sendPlayerAction,
    }
  },
  expectedRoutesForStatus: {
    [AVAILABLE_STATUS.notReady]: 'multiProjectStandardWelcome',
    [AVAILABLE_STATUS.waitForStart]: 'multiProjectStandardWelcome',
    [AVAILABLE_STATUS.readyToStartGame]: 'multiProjectStandardWelcome',
    [AVAILABLE_STATUS.cantStartProject]: 'multiProjectStandardStart',
    [AVAILABLE_STATUS.mustStartProject]: 'multiProjectStandardStart',
    [AVAILABLE_STATUS.canStartProjectBlueReady]: 'multiProjectStandardStart',
    [AVAILABLE_STATUS.resourcesSelection]: 'multiProjectStandardResourcesSelection',
    [AVAILABLE_STATUS.waitForResourcesCapacity]: 'multiProjectStandardWaitForRessource',
    [AVAILABLE_STATUS.canGetCapacity]: 'multiProjectStandardGetCapacity',
    [AVAILABLE_STATUS.canStartProjectBlueOverCapacity]: 'multiProjectStandardBlueOverCapacity',
    [AVAILABLE_STATUS.capacityReceived]: 'multiProjectStandardCapacityReceived',
    [AVAILABLE_STATUS.mustChangeEndDate]: 'multiProjectStandardMustChangeEndDate',
    [AVAILABLE_STATUS.waitToStartNewWeek]: 'multiProjectStandardWaitNewWeek',
    [AVAILABLE_STATUS.readyToStartNewWeek]: 'multiProjectStandardStartNewWeek',
    [AVAILABLE_STATUS.readyToStartNewWeekProjectNotStarted]: 'multiProjectStandardStartNewWeek',
    [AVAILABLE_STATUS.projectFinished]: 'multiProjectStandardProjectFinished',
  },
}
</script>
